import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        component: () => import('@/layouts/Default.vue'),
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('@/pages/Home.vue'),
            }
        ]
    },
    {
        path: '/contentstack',
        component: () => import('@/layouts/ContentStack.vue'),
        children: [
            {
                path: '/contentstack/fields/document',
                name: 'contentstack-fields-document',
                meta: {
                    premium: false
                },
                component: () => import('@/pages/contentstack/fields/MetaDocumentForLineup.vue')
            },
            {
                path: '/contentstack/fields/document-investor',
                name: 'contentstack-fields-document-meta',
                meta: {
                    premium: true
                },
                component: () => import('@/pages/contentstack/fields/MetaDocumentForLineup.vue')
            }
        ]
    },
    {
        path: '/article/clear-cache',
        name: 'article-clear-cache',
        component: () => import('@/pages/ArticleCache.vue'),
    },
    {
        path: '/afternoonnote',
        redirect: () => {
            const API_BASE_URL = process.env.VUE_APP_API_URL;
            const path = `${API_BASE_URL}/afternoonnote`;
            window.location.href = path;
        }
    }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;